<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
        <b-sidebar id="billAddCustomer" width="50rem" aria-labelledby="sidebar-no-header-title" no-header backdrop style="direction:ltr" right title="إضافة رسالة SMS" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{blockname}}</span>
                        </div>
                        <div @click="hide" id="closeAddcus" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>اغلاق</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="4">
                        <label>نوع العميل</label>
                        <b-input-group>
                            <b-form-select
                                class="selborder"
                                v-model="itemView.clientType"
                                :options="clientTypes"
                            ></b-form-select>
                        </b-input-group>
                    </v-col>
                    <v-col cols="4">
                        <label>اسم العميل</label>
                        <b-form-input
                            class="inborder"
                            v-model="itemView.clientName"
                            ></b-form-input>  
                    </v-col>
                    <v-col cols="4">
                        <label>رقم الاتصال</label>
                        <b-form-input
                            class="inborder"
                            v-model="itemView.mobile"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="4">
                        <label>السجل التجاري</label>
                        <b-form-input
                            class="inborder"
                            v-model="itemView.clientCRT"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="4">
                        <label>الرقم الضريبي</label>
                        <b-form-input
                            class="inborder"
                            v-model="itemView.clientVAT"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="4">
                        <label>الدومين</label>
                        <b-form-input
                            class="inborder"
                            v-model="itemView.clientDomain"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="4">
                        <label>تاريخ الانتهاء</label>
                        <b-form-input
                            class="inborder"
                            type="date"
                            v-model="itemView.expireDate"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="4">
                        <label>رقم الـ Anydesk</label>
                        <b-form-input
                            class="inborder"
                            v-model="itemView.anyDesk"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="4">
                        <label>username</label>
                        <b-form-input
                            class="inborder"
                            v-model="itemView.include_path"
                            style="direction:ltr"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="4">
                        <label>حالة الدعم</label>
                        <b-form-select
                            class="selborder"
                            v-model="itemView.supportStatus"
                            :options="supportStatuss"
                            ></b-form-select>
                    </v-col>
                    
                    <v-col cols="4">
                        <label>القاعدة</label>
                        <b-form-input
                            class="inborder"
                            v-model="itemView.databasename"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">{{buttonName}}</b-button>
                    </v-col>
                </v-row>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import {SnotifyPosition} from 'vue-snotify';
export default{
    name: 'SendMessage',
    data() {
        return {
            customerList: [],
            message: '',
            blockname: 'اضافة عميل',
            itemView: {
                id: 0,
                clientType: "ecars",
                clientName: "",
                clientCRT: "",
                clientVAT: "",
                clientDomain: "",
                expireDate: "",
                anyDesk: "",
                databasename: "",
                include_path: '',
                supportStatus: '',
                mobile: '',

            },
            buttonName: 'اضافـة',
        }
    },
    computed: {
        ...mapGetters({
            billState: `billStore/billState`,
        }),
        clientTypes: function(){
            let t=this.billState.clientTypes;
            
            return t;
        },
        supportStatuss: function(){
            let t = [
                {text: 'يحتاج إلى تعديل', value: 0},
                {text: 'خاضع للدعم', value: 1},
                {text: 'يحتاج إلى تجديد', value: 2},
                {text: 'جاهز للسداد', value: 3},
                {text: 'تم الغاء النسخة', value: 4},
            ];

            return t;
        }
    },
    methods:{
        ...mapActions({
            addCustomer: `billStore/addCustomer`,
        }),
        async addit(){
            if(this.message == ''){
                // this.$snotify.error('لا يمكن ارسال رسالة فارغة', 'Error', {
                //     timeout: 1000000,
                //     showProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     position: SnotifyPosition.centerCenter,
                //     buttons: [
                //         {
                //             text: 'اغلاق', 
                //             action: (toast) => {
                //                 this.$snotify.remove(toast.id); 
                //             } 
                //         },
                //     ]
                // });
                // return false;
            }
            await this.addCustomer(this.itemView);
            if(this.billState.messageStatus){
                this.$snotify.error('تمت الاضافة / التعديل بنجاح ', 'Success', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: 'اغلاق', 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                document.getElementById('closeAddcus').click();
                this.$parent.getClientList();
                return true;
            }else{
                
                this.$snotify.error('حدث خطأ بالارسال', 'Error', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: 'اغلاق', 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
        }
      
    }
}
</script>